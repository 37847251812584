<template>
  <main class="flex spaceBtw alignCenter ">
    <div class="leftContent flex flexColumn spaceBtw">
      <div class="plusJakarta top flex flexColumn ">   
        <h1 class="heavy">
          An editor that
          helps you write 
          clean codes.
        </h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu.</p>
        <button class=" font15 plusJakarta link pointer noBorder bgblack white br3 ">Try our free editor</button>
      </div>
      <div class="bottom flex flexColumn">
        <div class="star">
          <img src="../assets/Vector.png" alt="">
          <img src="../assets/Vector.png" alt="">
          <img src="../assets/Vector.png" alt="">
          <img src="../assets/Vector.png" alt="">
          <img src="../assets/Vector.png" alt="">
        </div>
        <p class="plusJakarta super-bold">Best code editor in market!</p>
        <p class="lorem inter">Consectetur adipiscing elit. Vehicula massa in enim luctus. Rutrum arcu, aliquam nulla tincidunt gravida. Cursus convallis dolor semper pretium ornare.</p>
        <span class="flex gap10 plusJakarta font15 super-bold flex">
          <img src="../assets/profile.png" class="profile" alt=""><p>Denny Jones</p>
        </span>
      </div>
    </div>
    <div class="rightContent" >
        <img src="../assets/Group15.jpg" alt="">
    </div>
  </main>
    <!-- <div class="btm">
    <div class="content bgblue">

    </div>
  </div>
  <footer class="flexEnd flexColumn alignCenter">

  </footer> -->
</template>

<script>

export default {
  name: 'Home',
  components: {},
  data(){
    return{
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
main{
  display: inline-block;
  width: 90%;
  padding-top: 40px;
}
.leftContent{
  padding: 30px;
  gap: 50px;
  flex-wrap: wrap;
}
.leftContent h1{
  font-size: 6vh;
}
.leftContent img{
  max-width: 17px;
}
.top{
  width: 100%;
  gap: 29px;
  max-width: 450px;
}
.bottom{
  width: 100%;
  gap: 29px;
  max-width: 450px;
}
.profile{
  width: 26px;
}
.top p{
  color: #52525B;
}
.top button{
  padding: 16px;
}
.rightContent{
  padding: 0px;
}
.lorem{
  color: #3F3F46;
}
.bottom span{
  width: 100%;
}
</style>
