import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Features from '../views/Features.vue'
import Support from '../views/Support.vue'
import Pricing from '../views/Pricing.vue'
import NotFound from '../views/NotFound.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/features',
    name: 'features',
    component: Features
  },
  {
    path: '/support',
    name: 'support',
    component: Support
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
