<template>

        <div class="modal br5 flexColumn spaceEven alignCenter trans3">
            <router-link to="/features" class="font15 plusJakarta link"><p>Features</p></router-link>
            <router-link to="/pricing" class="font15 plusJakarta link"><p>Pricing</p></router-link>
            <router-link to="/support" class="font15 plusJakarta link"><p>Support</p></router-link>
    </div>
</template>

<script>
export default {
    data(){
        return{
            showModal: true
        }
    },
    methods:{
        closeModal(){
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
/* .backdrop{
    width: 100%;
    height: 100%;
    top: 0px;
} */
.modal{
    width: 90%;
    height: 150px;
    background: #Ffff; 
    border: 1px solid #bebebf;
}
p{
   font-weight: bold;
  color: #2c3e50;
}
</style>