<template>
    <nav class="flex spaceBtw alignCenter">
      <router-link to="/" class="home font15 plusJakarta link logo uppercase super-bold">Rareblocks</router-link> 
      <span class="gap60 links flex spaceBtw">
        <router-link to="/features" class="home font15 plusJakarta link">Features</router-link>
        <router-link to="/pricing" class="home font15 plusJakarta link">Pricing</router-link>
        <router-link to="/support" class="home font15 plusJakarta link">Support</router-link>
      </span>
    <button class="home font15 plusJakarta link pointer noBorder bgblack white br3 primaryBtn ">Try for free</button>
    <button class="Menu flex justifyContent alignCenter noBorder br5" @click="toggleShowModal">
      <img  src="../src/assets/Nav.png" alt="" >
    </button>
  </nav>
  <Modal v-if="showModal" class="drpdwn" @close="toggleShowModal "/>
  <router-view/>
</template>

<script>
import Modal from './Modal.vue';
export default {
  components:{ Modal },
  data(){
    return{
      showModal: false,
    }
  },
  methods:{
    toggleShowModal(){
      this.showModal = !this.showModal
      console.log(this.showModal)
    }
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
nav{
  width: 95%;
  padding: 30px;
  padding-top: 24px;
  padding-left: 20px;
}
.logo::before{
  content: '/';
  position: relative;
  color: #F43F5E;
  z-index: -1;
}
.primaryBtn2{
  position: absolute;
  left: -100%;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav button{
  padding: 10px 20px 10px 20px;
}
.Menu{
  width: 50px;
  height: 50px;
}
.Menu img{
  width: 120%;
}
footer{
  width: 100%;
  padding: 48px 0px;
  background: #101828;
}
.btm{
  width: 100%;
  padding: 52px 0px 52px 0px;
  background: #1D2939;
  justify-content: center;
  display: flex;
  align-items: center;
}
.btm .content{
  padding:  0px 32px;
}
@media(min-width: 960px){
  .Menu{
    display: none;
  }
  .drpdwn{
    display: none;
  }
}
@media(min-width: 600px){
  .top button{
    width: 214px;
}
.rightContent img{
  max-width: 575px;
}
.star{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
}
@media(max-width: 960px){
 .primaryBtn{
    display: none;
  }
  nav span{
    display: none;
  }
  .links{
    display: none;
  }
}
@media(max-width: 600px){
  .leftContent{
    text-align: center;
    gap: 0px;
  }
  .leftContent h1{
    font-size: 2px;
  }
  .rightContent img{
    max-width: 360px;
  }
  .star{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  .leftContent span{
    justify-content: center;
  }
}
@media(max-width: 1000px){
  .logo{
    font-size: 20px;
    padding-left: 20px;
  }
}
@media(max-width: 1250px){
  main{
    flex-direction: column;
    justify-content: flex-start;
  }
  .leftContent h1{
    font-size: 40px;
  }
}
</style>
