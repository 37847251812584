<template>
  <div class="card flexColumn justifyContent alignCenter spaceEven">
    <img src="../assets/404Error.jpg" class="mainImg" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>
.card{
    width: 415px;
    height: 415.15px;
    background: #fff;
}
.mainImg{
    width: 415px;
    height: 415.15px;
}
</style>